<template>
  <div class="sceneryCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>查看日志</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" border>
      <el-table-column align="center" prop="logId" label="ID">
      </el-table-column>
      <el-table-column align="center" prop="masterName" label="操作人">
      </el-table-column>
      <el-table-column align="center" prop="addTime" label="最后修改时间">
      </el-table-column>
      <el-table-column align="center" prop="updateType" label="操作类型">
      </el-table-column>
      <el-table-column align="center" prop="" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="viewLog(row.logId)"
            v-if="$store.state.powerList.indexOf('pack:log:list:edit') !== -1"
            >查看日志</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="查看日志详情"
      :visible.sync="viewClassADialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="礼包名称：">
          <span>{{ viewInfoData.packName }}</span>
        </el-form-item>
        <el-form-item label="操作人：">
          <span>{{ viewInfoData.masterName }}</span>
        </el-form-item>
        <el-form-item label="操作类型：">
          <span>{{ viewInfoData.updateType }}</span>
        </el-form-item>
        <el-form-item label="操作时间：">
          <span>{{ viewInfoData.addTime }}</span>
        </el-form-item>
        <template
          v-if="
            viewInfoData.typeId != 19 &&
            viewInfoData.typeId != 21 &&
            viewInfoData.typeId != 22
          "
        >
          <el-form-item label="操作前内容：">
            <span>{{ viewInfoData.curContent }}</span>
          </el-form-item>
          <el-form-item label="操作后内容：">
            <span>{{ viewInfoData.endContent }}</span>
          </el-form-item>
        </template>
        <template v-else>
          <template v-if="viewInfoData.typeId == 21">
            <el-form-item label="操作前内容：">
              <block
                v-for="(item, index) in viewInfoData.curContent"
                :key="index"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="viewInfoData.curContent"
                ></el-image>
              </block>
            </el-form-item>
            <el-form-item label="操作后内容：">
              <block
                v-for="(item, index) in viewInfoData.endContent"
                :key="index"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="viewInfoData.endContent"
                ></el-image>
              </block>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="操作前内容：">
              <el-image
                style="width: 100px; height: 100px"
                :src="viewInfoData.curContent"
                :preview-src-list="[viewInfoData.curContent]"
              ></el-image>
            </el-form-item>
            <el-form-item label="操作后内容：">
              <el-image
                style="width: 100px; height: 100px"
                :src="viewInfoData.endContent"
                :preview-src-list="[viewInfoData.endContent]"
              ></el-image>
            </el-form-item>
          </template>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>
    <script>
//   import {
//     sceneryCate,
//     addSceneryCate,
//     changeSort,
//     editSceneryCate,
//     delSceneryCate,
//     changeStatus,
//   } from "../../../api/sceneryCate";
import {
  getGiftPackageLogList,
  getGiftPackageLogDetail,
} from "../../../api/seckillInfo";
//   import { sendFile } from "../../api/sendFile";
import SendImage from "../../../components/sendImage.vue";
export default {
  name: "sceneryCateList",
  components: {
    SendImage,
  },
  data() {
    return {
      list: {
        currentPage: 1,
        pageSize: 5,
        packId: "",
      },
      tableData: [],
      pagination: {},
      viewClassADialogVisible: false,
      viewInfoData: {},
    };
  },
  created() {
    this.list.packId = this.$route.query.packId;
    this.getSceneryCateList();
  },
  methods: {
    async viewLog(logId) {
      const { data } = await getGiftPackageLogDetail({
        logId,
      });
      if (data.code == 0) {
        this.viewInfoData = data.data;
        this.viewClassADialogVisible = true;
      }
    },
    async getSceneryCateList() {
      const { data } = await getGiftPackageLogList(this.list);
      this.pagination = data.pagination;
      this.tableData = data.list;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getSceneryCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getSceneryCateList();
    },
  },
};
</script>
    <style lang="less" scoped>
.sceneryCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          td {
            .showimg {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  /deep/.addDialog,
  /deep/ .editDialog {
    .el-dialog__body {
      .el-form {
        .el-form-item__content {
          display: flex;
          span {
            color: red;
          }
          .img {
            position: relative;
            height: 100px;
            width: 100px;
            margin: 0 20px;
            img {
              height: 100px;
              width: 100px;
              border: 1px solid #c0ccda;
            }
            div {
              position: absolute;
              top: 0;
              right: 5px;
              height: 10px;
              width: 10px;
              z-index: 100;
            }
          }
          .addImg {
            display: inline-block;
            width: 100px;
            height: 100px;
            border: 1px dashed #c0ccda;
            text-align: center;
            line-height: 100px;
            cursor: pointer;
            i {
              width: 50px;
              height: 50px;
              font-size: 20px;
              color: #c0ccda;
            }
          }
        }
      }
    }
    .log {
      margin-top: 20px;
    }
    .log:first-child {
      margin-top: 0px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>